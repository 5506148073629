import React from 'react';
import img from './AtivaLogo.png';
import { isMobile } from '../../utils';

export default function AtivaBrasilLogo() {
  if (isMobile) {
    return <img src={img} style={{ maxWidth: '150px' }}></img>;
  } else {
    return <img src={img} style={{ maxWidth: '150px' }}></img>;
  }
}
