import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import PrintIcon from './PrintIcon';
import MainParticipant from '../../MainParticipant/MainParticipant';
import { RiScreenshot2Line } from 'react-icons/ri';
import $ from 'jquery';

export default function PrintButton() {
  return (
    <Button
      onClick={() => {
        var video = document.getElementsByTagName('video')[0];
        var scale = 2;
        var videoAvatar = document.createElement('canvas');
        videoAvatar.width = video.videoWidth * scale;
        videoAvatar.height = video.videoHeight * scale;
        var ctx = videoAvatar.getContext('2d');
        if (ctx != null) ctx.drawImage(video, 0, 0, videoAvatar.width, videoAvatar.height);

        var rota = window.location.href;
        var hash;
        var base64 = videoAvatar.toDataURL();
        var base64Short = base64.substr(base64.indexOf(',') + 1);
        hash = rota.split('/');

        ///auto/regulacao/click/" + hash + "/fotos",
        //images.push( {conteudo: base64Short, arquivo: titulo, categoria: categoria} );
        var image = {
          conteudo: base64Short,
          arquivo: 'Foto_TeleReg.png',
          categoria: 'categoria',
        };

        var modulo = '';

        if (hash[3] == 'telereg') {
          // Regulação
          modulo = 'regulacao';
        } else if (hash[3] == 'teleinsp') {
          modulo = 'inspecao';
        }

        var hash_forcada = '6d54a5be0c7e3a2c51de39096876233fafb8eaee';
        //hash da regulacao atual
        //hash[5]
        $.ajax({
          type: 'POST',
          url: 'https://cosmos.ativapericias.com.br/auto/' + modulo + '/tele/' + hash[5] + '/fotos',
          data: image,
        });
      }}
      startIcon={<RiScreenshot2Line style={{ color: '#707578' }} />}
    >
      Tirar foto
    </Button>
  );
}
