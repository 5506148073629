import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import React from 'react';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { isMobile } from 'react-device-detect';
import { styled } from '@material-ui/core/styles';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import AvatarIcon from '../../icons/AvatarIcon';

export default function MainParticipant() {
  const mainParticipant = useMainParticipant();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const [selectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  var handle = useFullScreenHandle();
  const videoPriority =
    (mainParticipant === selectedParticipant || mainParticipant === screenShareParticipant) &&
    mainParticipant !== localParticipant
      ? 'high'
      : null;
  if (isMobile) {
    return (
      <div onClick={handle.enter}>
        <FullScreen handle={handle}>
          <MainParticipantInfo participant={localParticipant}>
            <ParticipantTracks
              participant={localParticipant}
              videoOnly
              enableScreenShare={mainParticipant !== localParticipant}
              videoPriority={videoPriority}
              isLocalParticipant={mainParticipant === localParticipant}
            />
          </MainParticipantInfo>
        </FullScreen>
      </div>
    );
  } else {
    return (
      /* audio is disabled for this participant component because this participant's audio
              is already being rendered in the <ParticipantStrip /> component.  */
      <MainParticipantInfo participant={mainParticipant}>
        <ParticipantTracks
          participant={mainParticipant}
          videoOnly
          enableScreenShare={mainParticipant !== localParticipant}
          videoPriority={videoPriority}
          isLocalParticipant={mainParticipant === localParticipant}
        />
      </MainParticipantInfo>
    );
  }
}
