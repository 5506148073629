import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { DEFAULT_VIDEO_CONSTRAINTS } from '../../../constants';
import FlipCameraIcon from './FlipCameraIcon';
import { LocalVideoTrack } from 'twilio-video';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useVideoInputDevices } from '../../../hooks/deviceHooks/deviceHooks';
import useLocalVideoFlip from '../../../hooks/useLocalVideoFlip/useLocalVideoFlip';

export default function FlipCameraButton() {
  const [isVideoEnabled, flipVideoEnabled] = useLocalVideoFlip();
  const { localTracks, getLocalVideoTrack } = useVideoContext();
  const [supportsFacingMode, setSupportsFacingMode] = useState<Boolean | null>(null);
  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;
  const mediaStreamTrack = useMediaStreamTrack(videoTrack);
  const videoDeviceList = useVideoInputDevices();

  useEffect(() => {
    const currentFacingMode = mediaStreamTrack?.getSettings().facingMode;
    if (currentFacingMode && supportsFacingMode === null) {
      setSupportsFacingMode(true);
    }
  }, [mediaStreamTrack, supportsFacingMode]);

  const toggleFacingMode = useCallback(() => {
    flipVideoEnabled();
  }, [flipVideoEnabled]);

  return supportsFacingMode && videoDeviceList.length > 1 ? (
    <Button onClick={toggleFacingMode} disabled={!videoTrack} startIcon={<FlipCameraIcon />}>
      Trocar câmera
    </Button>
  ) : null;
}
