import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import { isMobile } from './utils';
import { Fetch } from 'react-request';
import { FiAlertCircle } from 'react-icons/fi';

const VideoApp = () => {
  var rota = window.location.href;
  var hash;
  hash = rota.split('/');
  const { error, setError } = useAppState();

  // var connectionOptions;
  // connectionOptions = {
  //     video: { height: 1080, frameRate: 60, width: 1920 },
  //     maxAudioBitrate: 16000,
  // };
  // return (
  //     <UnsupportedBrowserWarning>
  //         <VideoProvider options={connectionOptions} onError={setError}>
  //             <ErrorDialog dismissError={() => setError(null)} error={error} />
  //             <App />
  //         </VideoProvider>
  //     </UnsupportedBrowserWarning>
  // );

  if (hash[3] == 'telereg') {
    return (
      <Fetch url={'https://cosmos.ativapericias.com.br/auto/telereg/' + hash[5]} method={'GET'} mode={'cors'}>
        {({ fetching, failed, data }) => {
          if (fetching) {
            if (isMobile) {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#326afa',
                      height: '20%',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: 'white', fontSize: '100%' }}>
                      <FiAlertCircle />
                      &nbsp;Buscando dados
                    </p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#326afa',
                      height: '20%',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: 'white', fontSize: '200%' }}>
                      <FiAlertCircle />
                      &nbsp;Buscando dados
                    </p>
                  </div>
                </div>
              );
            }
          }

          if (failed) {
            if (isMobile) {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#eb4034',
                      height: '20%',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: 'white', fontSize: '100%' }}>
                      <FiAlertCircle />
                      &nbsp;Erro! Essa pagina não está disponível.
                    </p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#eb4034',
                      height: '20%',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: 'white', fontSize: '200%' }}>
                      <FiAlertCircle />
                      &nbsp;Erro! Essa pagina não está disponível.
                    </p>
                  </div>
                </div>
              );
            }
          }

          if (data) {
            if (data.reg_disponiveltele == 'ativo') {
              var connectionOptions;
              connectionOptions = {
                video: { height: 1080, frameRate: 60, width: 1920 },
                maxAudioBitrate: 16000,
              };
              return (
                // <UnsupportedBrowserWarning>
                <VideoProvider options={connectionOptions} onError={setError}>
                  <ErrorDialog dismissError={() => setError(null)} error={error} />
                  <App />
                </VideoProvider>
                // </UnsupportedBrowserWarning>
              );
            } else {
              if (isMobile) {
                return (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#eb4034',
                        height: '20%',
                        width: '100%',
                      }}
                    >
                      <p style={{ color: 'white', fontSize: '100%' }}>
                        <FiAlertCircle />
                        &nbsp;Regulação Inativa
                      </p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#eb4034',
                        height: '20%',
                        width: '100%',
                      }}
                    >
                      <p style={{ color: 'white', fontSize: '200%' }}>
                        <FiAlertCircle />
                        &nbsp;Regulação Inativa
                      </p>
                    </div>
                  </div>
                );
              }
            }
          }

          return null;
        }}
      </Fetch>
    );
  } else {
    return (
      <Fetch url={'https://cosmos.ativapericias.com.br/auto/teleinsp/' + hash[5]} method={'GET'} mode={'cors'}>
        {({ fetching, failed, data }) => {
          if (fetching) {
            if (isMobile) {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#326afa',
                      height: '20%',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: 'white', fontSize: '100%' }}>
                      <FiAlertCircle />
                      &nbsp;Buscando dados
                    </p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#326afa',
                      height: '20%',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: 'white', fontSize: '200%' }}>
                      <FiAlertCircle />
                      &nbsp;Buscando dados
                    </p>
                  </div>
                </div>
              );
            }
          }

          if (failed) {
            if (isMobile) {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#eb4034',
                      height: '20%',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: 'white', fontSize: '100%' }}>
                      <FiAlertCircle />
                      &nbsp;Erro! Essa pagina não está disponível.
                    </p>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#eb4034',
                      height: '20%',
                      width: '100%',
                    }}
                  >
                    <p style={{ color: 'white', fontSize: '200%' }}>
                      <FiAlertCircle />
                      &nbsp;Erro! Essa pagina não está disponível.
                    </p>
                  </div>
                </div>
              );
            }
          }

          if (data) {
            if (data.ins_disponiveltele == 'ativo') {
              var connectionOptions;
              connectionOptions = {
                video: { height: 1080, frameRate: 60, width: 1920 },
                maxAudioBitrate: 16000,
              };
              return (
                // <UnsupportedBrowserWarning>
                <VideoProvider options={connectionOptions} onError={setError}>
                  <ErrorDialog dismissError={() => setError(null)} error={error} />
                  <App />
                </VideoProvider>
                // </UnsupportedBrowserWarning>
              );
            } else {
              if (isMobile) {
                return (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#eb4034',
                        height: '20%',
                        width: '100%',
                      }}
                    >
                      <p style={{ color: 'white', fontSize: '100%' }}>
                        <FiAlertCircle />
                        &nbsp;Inspeção Inativa
                      </p>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#eb4034',
                        height: '20%',
                        width: '100%',
                      }}
                    >
                      <p style={{ color: 'white', fontSize: '200%' }}>
                        <FiAlertCircle />
                        &nbsp;Inspeção Inativa
                      </p>
                    </div>
                  </div>
                );
              }
            }
          }

          return null;
        }}
      </Fetch>
    );
  }
};

const VideoAppGuest = () => {
  const { error, setError } = useAppState();
  const connectionOptions = {
    video: { height: 1080, frameRate: 60, width: 1920 },
    maxAudioBitrate: 16000,
  };

  return (
    <UnsupportedBrowserWarning>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ErrorDialog dismissError={() => setError(null)} error={error} />
        <App />
      </VideoProvider>
    </UnsupportedBrowserWarning>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <AppStateProvider>
        <Switch>
          <PrivateRoute path={'/telereg/room/:URLRoomName'}>
            <VideoApp />
          </PrivateRoute>
          <PrivateRoute path="/teleinsp/room/:URLRoomName">
            <VideoApp />
          </PrivateRoute>
        </Switch>
      </AppStateProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
