import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';
import AtivaBrasilLogo from '../IntroContainer/AtivaBrasilLogo';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: 'white',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.8em',
    paddingRight: '1em',
    marginRight: '1em',
    minWidth: 'auto',
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    },
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      <div style={{ flex: 1, justifyContent: 'flex-start', marginTop: 'auto', marginBottom: 'auto' }}>
        <AtivaBrasilLogo />
      </div>
      {/* <Typography variant="subtitle1"></Typography> */}
      <div style={{ marginBottom: 'auto', marginTop: 'auto', display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
        <Menu buttonClassName={classes.settingsButton} />
        <EndCallButton className={classes.endCallButton} />
      </div>
    </Grid>
  );
}
