import { LocalVideoTrack } from 'twilio-video';
import { useCallback } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useLocalVideoFlip() {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
  } = useVideoContext();
  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;

  const flipVideoEnabled = useCallback(() => {
    if (videoTrack) {
      const newFacingMode = videoTrack.mediaStreamTrack?.getSettings().facingMode === 'user' ? 'environment' : 'user';
      const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
      localParticipant?.emit('trackUnpublished', localTrackPublication);
      removeLocalVideoTrack();
      getLocalVideoTrack({ facingMode: newFacingMode })
        .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
        .catch(onError);
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, onError, removeLocalVideoTrack]);

  return [!!videoTrack, flipVideoEnabled] as const;
}
