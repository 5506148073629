import React, { ChangeEvent, FormEvent } from 'react';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import { isMobile } from 'react-device-detect';
import $ from 'jquery';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

var localizacao: string;
var nome: string;

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    nome = event.target.value;
    setName(event.target.value);
  };

  const NameChangeCliente = () => {
    if (localizacao == undefined) {
      localizacao = ' (-)';
    } else {
      var rota = window.location.href;
      var hash;
      hash = rota.split('/');
      console.log(hash);

      $.ajax({
        type: 'POST',
        url: 'https://cosmos.ativapericias.com.br/auto/telereg/' + hash[5] + '/coordenada',
        data: {
          coordenada: localizacao,
        },
      });

      localizacao = ' (' + localizacao + ')';
    }
    setName(nome + '' + localizacao);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;
  if (isMobile) {
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Entrar na sala
        </Typography>
        <Typography variant="body1">{hasUsername ? '' : 'Digite seu nome para entrar na sala'}</Typography>
        <form onSubmit={handleSubmit}>
          <div className={classes.inputContainer}>
            {!hasUsername && (
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name">
                  Seu nome
                </InputLabel>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                  onClick={() => {
                    var options = {
                      enableHighAccuracy: true,
                      timeout: 5000,
                      maximumAge: 0,
                    };
                    // navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                    navigator.geolocation.getCurrentPosition(
                      location => {
                        localizacao = location.coords.latitude + ',' + location.coords.longitude;
                      },
                      error => {
                        console.log(error);
                      },
                      options
                    );
                  }}
                />
              </div>
            )}
            <div hidden className={classes.textFieldContainer}>
              <TextField
                autoCapitalize="false"
                id="input-room-name"
                variant="outlined"
                fullWidth
                size="small"
                value={roomName}
                onChange={handleRoomNameChange}
              />
            </div>
          </div>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name || !roomName}
              className={classes.continueButton}
              onClick={NameChangeCliente}
            >
              Continuar
            </Button>
          </Grid>
        </form>
      </>
    );
  } else {
    return (
      <>
        <Typography variant="h5" className={classes.gutterBottom}>
          Entrar na sala
        </Typography>
        <Typography variant="body1">{hasUsername ? '' : 'Digite seu nome para entrar na sala'}</Typography>
        <form onSubmit={handleSubmit}>
          <div className={classes.inputContainer}>
            {!hasUsername && (
              <div className={classes.textFieldContainer}>
                <InputLabel shrink htmlFor="input-user-name">
                  Seu nome
                </InputLabel>
                <TextField
                  id="input-user-name"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            )}
            <div hidden className={classes.textFieldContainer}>
              <TextField
                autoCapitalize="false"
                id="input-room-name"
                variant="outlined"
                fullWidth
                size="small"
                value={roomName}
                onChange={handleRoomNameChange}
              />
            </div>
          </div>

          <Grid container justify="flex-end">
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={!name || !roomName}
              className={classes.continueButton}
            >
              Continuar
            </Button>
          </Grid>
        </form>
      </>
    );
  }
}
